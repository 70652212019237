import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Container = Styled.div`
    text-align: center;
    padding: 10px;
    background: rgb(123,195,218);
    background: -moz-linear-gradient(122deg, rgba(123,195,218,1) 0%, rgba(88,168,193,1) 100%);
    background: -webkit-linear-gradient(122deg, rgba(123,195,218,1) 0%, rgba(88,168,193,1) 100%);
    background: linear-gradient(122deg, rgba(123,195,218,1) 0%, rgba(88,168,193,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7bc3da",endColorstr="#58a8c1",GradientType=1);

    @media screen and (min-width: 720px) {
        background: none;
        margin: 25px;
        box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.25);
    }
`
const ImageLight = Styled(Img)`
    float: left;
    width: 175px;

    @media screen and (min-width: 720px) {
        display: none;    
    }
`
const ImageDark = Styled(Img)`
    display: none;
    float: left;
    width: 175px;

    @media screen and (min-width: 720px) {
        display: inline-block;    
    }
`
const Text = Styled.p`
    color: #fff;
    font-size: 16px;
    text-align: justify;
    
    @media screen and (min-width: 720px) {
        color: #BDBDBD;
    }
`
const Link = Styled(AniLink)`
    display: inline-block;
    color: #fff;
    font-size: 18px;
    border: 2px solid #fff;
    border-radius: 15px;
    padding: 10px;
    margin: 25px auto;
    text-decoration: none;
    cursor: pointer;
    transition: all .25s ease;

    &:hover, &:focus {
        background: #fff;
        color: #999;
    }

    @media screen and (min-width: 720px) {
        color: #7BC3DA;
        border-color: #7BC3DA;

        &:hover, &:focus {
            background: #7BC3DA;
            color: #fff;
        }
    }
`

const AboutSexTherapyCard = () => (
    <StaticQuery 
        query={graphql`
            query {
                couple: file(relativePath: { eq: "couple.png" }) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 175) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                couple2: file(relativePath: { eq: "couple-2.png" }) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 175) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
        `}
        render={data => (
            <Container>
                <ImageLight fluid={data.couple.childImageSharp.fluid} alt='Silhouette of two people making a heart shape.' />
                <ImageDark fluid={data.couple2.childImageSharp.fluid} alt='Silhouette of two people making a heart shape.' />
                <Text>Sex Therapy is a sub-specialty of psychotherapy, focusing on the specific concerns related to human sexuality. People of all ages, creeds, health status, ethnic backgrounds, whether partnered or single, may benefit from working with a psychotherapist who specializes in this area. Certified Sex Therapists use specialized clinical skills and theoretical knowledge to help people solve their sexual concerns.</Text>
                <Link cover direction='left' bg='#7BC3DA' to='/sexTherapy'>More About Sex Therapy</Link>
            </Container>
        )}
    />
)

export default AboutSexTherapyCard

import React from 'react'
import Wrapper from '../components/wrapper'
import SEO from '../components/seo'
import Body from '../components/body'
import HomePageLayout from '../components/homePageLayout'
import Header from '../components/header'
import Banner from '../components/banner'
import Specialties from '../components/specialties'
import AboutKimCard from '../components/aboutKimCard'
import ContactSnippet from '../components/contactSnippet'
import AboutSexTherapyCard from '../components/aboutSexTherapyCard'
import KimSnippet from '../components/kimSnippet'
import ContactCard from '../components/contactCard'
import Footer from '../components/footer'

const IndexPage = () => (
  <Wrapper>
    <SEO title="Home" />
    <Header />
    <Body>
        <Banner />
        <Specialties />
        <HomePageLayout>
            <AboutKimCard />
            <ContactSnippet />
            <AboutSexTherapyCard />
            <KimSnippet />
            <ContactCard />
        </HomePageLayout>
    </Body>
    <Footer />
  </Wrapper>
)

export default IndexPage

import React from 'react'
import Styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Container = Styled.div`
    text-align: center;
    font-size: 24px;
    margin: 75px;
`
const Link = Styled(AniLink)`
    color: #7BC3DA;
`

const ContactSnippet = () => (
    <Container>
        <a href='/#contact'>Contact</a> Kim now to schedule a free 30-minute consultation.
    </Container>
)

export default ContactSnippet

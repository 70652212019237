import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Styled from 'styled-components'
import moment from 'moment'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Container = Styled.div`
    padding: 10px;
    margin: 25px;
    text-align: center;
    box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.25);
`
const Title = Styled.h3`
    color: #4F4F4F;
    font-size: 18px;
    font-weight: normal;
`
const Image = Styled(Img)`
    float: left;
    width: 125px;
    margin: 5px;
    border-radius: 50%;
    filter: grayscale(100%);
`
const Text = Styled.p`
    color: #BDBDBD;
    font-size: 16px;
    text-align: justify;
`
const Link = Styled(AniLink)`
    display: inline-block;
    color: #7BC3DA;
    font-size: 18px;
    border: 2px solid #7BC3DA;
    border-radius: 15px;
    padding: 10px;
    margin: 25px auto;
    text-decoration: none;
    cursor: pointer;
    transition: all .25s ease;

    &:hover, &:focus {
        background: #7BC3DA;
        color: #fff;
    }
`

const AboutKimCard = () => {
    const startingDate = moment('1999-09-01')
    const yearsOfExperience = moment().diff(startingDate, 'years')

    return (
        <StaticQuery 
            query={graphql`
                query {
                    kim: file(relativePath: { eq: "kim.jpg" }) {
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 125) {
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            `}
            render={data => (
                <Container>
                    <Title>Dr. Kimberly Ludwigsen, PhD, MFT, LCSW, CST</Title>
                    <Image fluid={data.kim.childImageSharp.fluid} alt='Dr. Kimberly Ludwigsen' />
                    <Text>A New Jersey Licensed Clinical Social Worker with more than {yearsOfExperience} years of experience treating individuals, couples, families, and children. Her specialties include sexual satisfaction, parenting issues, post-partum depression, extramarital affairs and stress management.</Text>
                    <Link cover direction='left' bg='#7BC3DA' to='/aboutKim'>More About Kim</Link>
                </Container>
            )}
        />
    )
}

export default AboutKimCard

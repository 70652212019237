import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import Styled from 'styled-components'

const Container = Styled.div`
    height: 250px;
    position: relative;
    transition: all .25s ease;
    
    @media screen and (min-width: 720px) {
        height: 350px;
    }
    
    @media screen and (min-width: 900px) {
        margin: 25px;
        box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.25);
    }
`
const CallKimButton = Styled.a`
    position: absolute;
    top: 50px;
    left: 25%;
    background: #27AE60;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
    padding: 5px;
    width: 150px;
`
const Icon = Styled(Img)`
    width: 50px;
    float: left;
`

const Banner = () => (
    <StaticQuery 
        query={graphql`
            query {
                banner: file(relativePath: { eq: "banner.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 900) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                iconPhone: file(relativePath: { eq: "icon-phone.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 250) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
        `}
        render={data => (
            <Container>
                <BackgroundImage
                    tag='section' 
                    fluid={data.banner.childImageSharp.fluid} 
                    backgroundColor={`#f2f2f2`} 
                    style={{
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '100%'
                    }}
                    alt='Person sitting on dock with moutains in the background.'
                />
                <CallKimButton href='tel:856-448-4352'>
                    <Icon fluid={data.iconPhone.childImageSharp.fluid} />
                    <h2>Call Kim</h2>
                    <p>(856) 448-4352</p>
                </CallKimButton>
            </Container>
        )}
    />
)

export default Banner

import React from 'react'
import Styled from 'styled-components'

const Container = Styled.div`
    padding: 10px;
    margin: 25px;
    text-align: center;
    box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.25);
`
const Title = Styled.h3`
    color: #4F4F4F;
    font-size: 18px;
    font-weight: normal;
`
const Number = Styled.p`
    font-size: 32px;
    margin-top: 20px;
`
const TextOrCall = Styled.p`
    font-size: 24px;
    margin-bottom: 20px;

    & > a {
        color: #7BC3DA;
    }
`
const Address = Styled.p`
    font-size: 24px;
    color: #333333;
`

const ContactCard = () => (
    <Container id='contact'>
        <Title>Contact Kim</Title>
        <Number>(856) 448-4352</Number>
        <TextOrCall>Text or <a href='tel:856-448-4352'>Call</a></TextOrCall>
        <Address>1 Sheppard Road, Suite 703</Address>
        <Address>Voorhees, NJ 08043</Address>
    </Container>
)

export default ContactCard

import React from 'react'
import Styled from 'styled-components'

const Container = Styled.div`
    text-align: center;
    font-size: 24px;
    margin: 75px;
`

const KimSnippet = () => (
    <Container>
        South Jersey's foremost sex therapist.
    </Container>
)

export default KimSnippet

import React from 'react'
import Styled from 'styled-components'

const Container = Styled.div`
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: 900px) {
        grid-template-columns: 1fr 1fr;
        
        & > div:nth-child(2) {
            grid-row: 1;
            grid-column: 1 / span 2;
        }
        & > div:nth-child(4) {
            grid-column: 1 / span 2;
        }
        & > div:nth-child(5) {
            grid-column: 1 / span 2;
        }
    }
`

const HomePageLayout = ({ children }) => (
    <Container>
        {children}
    </Container>
)

export default HomePageLayout

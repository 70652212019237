import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Styled from 'styled-components'

const Container = Styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    @media screen and (min-width: 720px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media screen and (min-width: 900px) {
        margin: 25px;
        box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.25);
    }
`
const Item = Styled.div`
    height: 50vw;

    &:nth-child(2), &:nth-child(3), &:nth-child(6), &:nth-child(7) {
        background: rgb(123,195,218);
        background: -moz-linear-gradient(122deg, rgba(123,195,218,1) 0%, rgba(88,168,193,1) 100%);
        background: -webkit-linear-gradient(122deg, rgba(123,195,218,1) 0%, rgba(88,168,193,1) 100%);
        background: linear-gradient(122deg, rgba(123,195,218,1) 0%, rgba(88,168,193,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7bc3da",endColorstr="#58a8c1",GradientType=1);
    }

    @media screen and (min-width: 720px) {
        height: 25vw;

        &:nth-child(2) {
            grid-row: 2 / span 1;
            grid-column: 1 / span 1;
        }
        &:nth-child(4) {
            grid-row: 2 / span 1;
            grid-column: 2 / span 1;
        }
        &:nth-child(6) {
            grid-row: 2 / span 1;
            grid-column: 3 / span 1;
        }
    }

    @media screen and (min-width: 900px) {
        height: 225px;
    }
`
const ItemImage = Styled(Img)`
    width: 100%;
    height: 100%;
`
const ItemText = Styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const ItemTitle = Styled.h3`
    font-family: 'Rock Salt';
    font-size: 24px;
    font-weight: normal;
    color: #7BC3DA;
`
const ItemDescription = Styled.p`
    font-size: 16px;
    text-align: center;
`

const Specialties = () => (
    <StaticQuery 
        query={graphql`
            query {
                iconSex: file(relativePath: { eq: "icon-sex.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                iconTrauma: file(relativePath: { eq: "icon-trauma.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                iconRainbow: file(relativePath: { eq: "icon-rainbow.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
                iconIllness: file(relativePath: { eq: "icon-illness.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 450) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
        `}
        render={data => (
            <Container>
                <Item>
                    <ItemText>
                        <ItemTitle>Sex</ItemTitle>
                        <ItemDescription>individul, couples and sexual dysfunction therapy</ItemDescription>
                    </ItemText>
                </Item>
                <Item>
                    <ItemImage fluid={data.iconSex.childImageSharp.fluid} alt='Holding hands.' />
                </Item>
                <Item>
                    <ItemImage fluid={data.iconTrauma.childImageSharp.fluid} alt='Distressed person.' />
                </Item>
                <Item>
                    <ItemText>
                        <ItemTitle>Trauma</ItemTitle>
                        <ItemDescription>therapy</ItemDescription>
                    </ItemText>
                </Item>
                <Item>
                    <ItemText>
                        <ItemTitle>LGBTQA</ItemTitle>
                        <ItemDescription>related issues</ItemDescription>
                    </ItemText>
                </Item>
                <Item>
                    <ItemImage fluid={data.iconRainbow.childImageSharp.fluid} alt='Rainbow.' />
                </Item>
                <Item>
                    <ItemImage fluid={data.iconIllness.childImageSharp.fluid} alt='Injured heart.' />
                </Item>
                <Item>
                    <ItemText>
                        <ItemTitle>Chronic</ItemTitle>
                        <ItemDescription>illness</ItemDescription>
                    </ItemText>
                </Item>
            </Container>
        )}
    />
)

export default Specialties

